
import { Delegate, DelegatesWithPagination } from "@/api/types";
import Spinner from "@/components/shared/Spinner.vue";
import { Component, ModelSync, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import TitleHeader from "../common/TitleHeader.vue";

const SDelegate = namespace("delegate");
const SEvents = namespace("events");
const SCompany = namespace("company");
@Component({
  components: { Spinner, TitleHeader }
})
export default class DelegatesList extends Vue {
  delegates: Array<Delegate> = [];
  filters = {
    company: null,
    name: "",
    id: "",
    active: 1
  };
  options: any = {};
  page = 1;
  limit = 10;
  headers = [
    {
      text: "Company",
      value: "company"
    },
    {
      text: "Delegate",
      value: "name"
    },
    {
      text: "Passport / ID",
      value: "passport"
    },
    {
      text: "Phone number",
      value: "cell_phone"
    },
    {
      text: "Actions",
      value: "controls",
      sortable: false,
      width: "50px"
    }
  ];
  loading = false;

  //ACTIONS

  @SDelegate.Action("getListDelegates")
  public getListDelegates!: (params: {
    page: number;
    filters: object;
    limit: number;
    withDeleted?: boolean;
  }) => Promise<any>;

  //STATE

  @SDelegate.State("delegatesWithPagination")
  delegatesWithPagination: any;
  @SDelegate.State("delegatesArray") delegatesArray: any;
  @SEvents.State("event") event: any;
  currentDelegatesWithPagination: DelegatesWithPagination = {
    meta: { perPage: this.limit }
  };

  @SCompany.Action("getCompanyById")
  getCompany!: (data: any) => Promise<any>;
  @SCompany.State("companies") companies: any;

  @ModelSync("selected", "changegetListDelegates", { type: Array })
  selectedValue!: any;

  get perPage() {
    return this.currentDelegatesWithPagination?.meta?.perPage || 0;
  }
  get total() {
    return this.currentDelegatesWithPagination?.meta?.total || 0;
  }

  async created() {
    this.loading = true;
    try {
      this.filters.company = this.filters.company || this.comapniesList();
      if (this.filters.company) {
        await this.loadDelegatesData(this.page, this.filters);
        this.currentDelegatesWithPagination = {
          ...this.delegatesWithPagination
        };
        this.getCompanies();
      }
    } catch (e) {
      await this.$error(e);
    }
    this.loading = false;
  }

  comapniesList() {
    const companiesId = this.event.company
      ?.map((c: any) => {
        return c.companyId;
      })
      .join(",");
    return companiesId || null;
  }

  async getCompanies() {
    try {
      const companies = this.event.company
        ?.map((c: any) => {
          return c.companyId;
        })
        .join(",");
      return this.getCompany({
        companyId: companies,
        withDeleted: this.event.company.length
      });
    } catch (e) {
      await this.$error(e);
    }
  }

  async loadDelegatesData(
    page = 1,
    filters: {
      company: string | null;
      name: string;
      id: string;
      active?: number;
    } = {
      company: null,
      name: "",
      id: "",
      active: 1
    }
  ) {
    try {
      this.loading = true;
      await this.getListDelegates({
        page: page,
        filters,
        limit: this.limit,
        withDeleted: !!this.event.company.length
      });
      this.currentDelegatesWithPagination = {
        ...this.delegatesWithPagination
      };
      this.delegates = this.currentDelegatesWithPagination?.data || [];
    } catch (e) {
      await this.$error(e);
    }
    this.loading = false;
  }
  isActive(id: number) {
    return !!this.selectedValue.find(
      (el: { id: number; companyId: number | string }) => el.id == id
    );
  }

  onAssign(id: number, companyId: number | string) {
    const itemIndex = this.selectedValue.findIndex(
      (el: { id: number; companyId: number | string }) => el.id == id
    );
    if (itemIndex === -1) {
      this.selectedValue.push({ id, companyId });
    } else {
      this.selectedValue.splice(itemIndex, 1);
    }
  }

  // @Watch("filters", { deep: true })
  async onChangeFilters() {
    this.filters.company = this.filters.company || this.comapniesList();
    await this.loadDelegatesData(this.page, this.filters);
  }

  @Watch("options", { deep: true })
  async onChangePage(val: any) {
    this.filters.company = this.filters.company || this.comapniesList();
    this.limit = val.itemsPerPage;
    if (this.limit === -1) this.limit = 9999;
    await this.loadDelegatesData(val.page, this.filters);
  }

  @Watch("event.company")
  async updEventCompanies(company: any) {
    this.filters.company = company.map((c: any) => c.companyId).join(",");
    await this.getCompanies();
    await this.loadDelegatesData(this.options.page, this.filters);
  }

  @Watch("companies", { deep: true })
  updCompanies() {
    this.filters.company = this.comapniesList();
    this.loadDelegatesData(this.page, this.filters);
  }
}
