
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import PageTitle from "../common/PageTitle.vue";

const Events = namespace("events");
const SCompany = namespace("company");
@Component({
  components: { PageTitle }
})
export default class CompanyPrice extends Vue {
  headersTitle = [{ icon: "mdi-domain", header: "Company / Price" }];
  isLoading = false;
  valid: any = false;
  goal: any[] = [{ price: undefined, companyId: undefined }];
  @Prop() selectedDelegates!: any;
  @Events.State("event") event: any;
  @Events.State("coursesInfo") coursesInfo: any;
  @Events.Mutation("setNewCompany")
  addNewCompany!: (company: any) => void;
  @Events.Mutation("updCompany")
  updCompanies!: (params: any) => void;
  @Events.Action("removeCompanies")
  removeCompanies!: (params: {
    eventId: number;
    delegates: any[];
    id: number;
    index: number;
  }) => Promise<void>;
  @SCompany.State("companyListWithPagination")
  companies: any;
  currentCompanies: any = [];
  @SCompany.Action("getCompanyById")
  getCompany!: (data: any) => Promise<any>;

  @Watch("companies")
  setCurrentCompanies() {
    this.currentCompanies = [...this.companies];
  }

  async mounted() {
    this.currentCompanies = [...this.companies];

    if (!this.event.company?.length) {
      this.addNewCompany({
        companyId: undefined,
        price: this.event.course?.price || undefined
      });
      this.goal = [...this.event.company];
    } else {
      this.goal = [...this.event.company];
    }
    this.checkCompanies();
  }
  @Watch("event.company", { deep: true })
  eventCompanyLoaded() {
    if (!this.event.company?.length) {
      this.addNewCompany({
        companyId: undefined,
        price: undefined
      });
      this.goal = [...this.event.company];
    } else {
      this.goal = [
        ...this.event.company.map((com: any) => ({
          ...com,
          companyId: String(com.companyId)
        }))
      ];
    }
    this.checkCompanies();
  }
  @Watch("coursesInfo")
  updCourse() {
    if (!this.event.company?.length || !this.event.company[0].companyId) {
      this.goal[0].price = +this.coursesInfo.price;
    }
  }
  setCompanies(index: number): void {
    this.checkCompanies();
    this.updCompany(index);
  }

  async updPriceCompany(index: number) {
    this.updCompanies({ index, data: this.goal[index] });
  }
  async updCompany(index: number) {
    this.updCompanies({ index, data: this.goal[index] });
    const companies = this.event.company
      ?.map((c: any) => {
        return c.companyId;
      })
      .join(",");
    await this.getCompany({
      companyId: companies,
      withDeleted: this.event.company.length
    });
  }
  addCompany() {
    this.valid = this.$refs.form as Vue;
    if (this.valid.validate()) {
      this.addNewCompany({
        price: +this.coursesInfo?.price || 0,
        companyId: undefined
      });
      this.goal.push({
        price: +this.coursesInfo?.price || 0,
        companyId: undefined
      });
    }
  }
  async removeCompany(id: number, companyId: number | string, index: number) {
    try {
      const delegates: any = {};
      this.selectedDelegates.forEach(
        (d: { companyId: number | string; id: number }) => {
          if (d.companyId !== companyId) {
            delegates[d.id] = companyId;
          }
        }
      );
      await this.removeCompanies({
        eventId: this.event.id,
        delegates,
        id,
        index
      });
      this.$emit("removeAssign", companyId);
      this.goal.splice(index, 1);
      this.checkCompanies();
    } catch (e) {
      await this.$error(e);
    }
  }
  checkCompanies(): void {
    this.currentCompanies.forEach((c: any, index: number) => {
      if (this.goal.some(com => com.companyId === c.id)) {
        this.currentCompanies[index].disabled = true;
      } else {
        this.currentCompanies[index].disabled = false;
      }
    });
  }
  companyRule(value: string) {
    return !!value || "Required.";
  }
}
