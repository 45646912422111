import { render, staticRenderFns } from "./CompanyPrice.vue?vue&type=template&id=347f2568&scoped=true&"
import script from "./CompanyPrice.vue?vue&type=script&lang=ts&"
export * from "./CompanyPrice.vue?vue&type=script&lang=ts&"
import style0 from "./CompanyPrice.vue?vue&type=style&index=0&id=347f2568&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "347f2568",
  null
  
)

export default component.exports